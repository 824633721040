@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&family=Alegreya:wght@400;500;600&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  color: rgb(var(--color-pencil));
  background: rgb(var(--color-paper2));
}

html, body, #root {
  overscroll-behavior-x: none;
  height: 100%;
}

html.dark input:not(.outline-none),
html.dark textarea:not(.outline-none) {
  outline-color: rgb(var(--color-gray-700));
}

.firebase-emulator-warning {
  display: none;
}

/* Animations */
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.spinning-500 {
  animation: 500ms linear spin infinite;
}

.spinning-1000 {
  animation: 1s linear spin infinite;
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes blink {
  0% { opacity: .3; }
  25% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: .3; }
}

/* Error Color */
.color-error-red {
  color: rgb(var(--color-error));
}

/*  CodeMirror fix */
body .CodeMirror {
  height: auto;
  min-height: 36px;
}
body .CodeMirror-wrap pre.CodeMirror-line {
  padding-left: .8em;
}
.CodeMirror-dialog {
  padding-left: 40px;
}


/* helpers */
.single-line-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;  
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(var(--color-scrollbar));
  border-radius: 4px;
}

.link-button {
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}


.normal {
  font-size: 14px;
  line-height: 1.5em;
}

.normal ul { list-style-type: disc; }
.normal ol { list-style-type: decimal; }
.normal li { margin-left: 1rem; }
.normal ul ul,
.normal ol ul {
 list-style-type: circle; 
 margin-left: 1.5rem;
}
.normal ol ol,
.normal ul ol {
 list-style-type: lower-latin; 
 margin-left: 1.5rem;
}

.normal p {
  margin-bottom: .25em;
}

h1 { font-size: 2.25rem; margin: 0 0 1rem 0; font-weight: 500; line-height: 1 }
h2 { font-size: 2.0rem; margin: 0 0 1rem 0; font-weight: 500; line-height: 1.125 }
h3 { font-size: 1.8rem; margin: 0 0 1rem 0; font-weight: 500; line-height: 1.25 }
h4 { font-size: 1.5rem; margin: 0 0 1rem 0; font-weight: 500; line-height: 1.25 }
h5 { font-size: 1.25rem; margin: 0 0 1rem 0; font-weight: 500; line-height: 1.5 }

h1:not(:first-child) { margin-top: 1.75rem;  }
h2:not(:first-child) { margin-top: 1.5rem;  }
h3:not(:first-child) { margin-top: 1.25rem;  }
h4:not(:first-child) { margin-top: 1.0rem;  }
h5:not(:first-child) { margin-top: 1.0rem;  }

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}


.scroll-snap {
  scroll-snap-type: y mandatory;
}

.snap-start {
  scroll-snap-align: start;
}

.limit-svg svg {
  width: 100%;
  height: 100%;
}


.bg-striped {
  background: linear-gradient(
    -45deg, rgb(var(--color-paper)) 10%, rgb(var(--color-wax)) 10%, rgb(var(--color-wax)) 50%,
    rgb(var(--color-paper)) 50%, rgb(var(--color-paper)) 60%, rgb(var(--color-wax)) 60%, rgb(var(--color-wax)) 100%
  );
  background-size: 21.21px 21.21px;
}

.fade-effect {
  animation-name: -fade-effect;
  animation-duration: 200ms;
}

@keyframes fade-effect {
  from {opacity: .4}
  to {opacity: 1}
}

.normal ul, ul { list-style-type: disc; margin-bottom: 0.5rem; }
.normal ol, ol { list-style-type: decimal; margin-bottom: 0.5rem; }
.normal li, li, ul li, ol li { margin-left: 1rem; }

ul li p , 
ol li p {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
p:not(:last-child) {
  margin-bottom: 1rem;
}
hr {
  border-top-color: rgb(var(--color-rim));
}

input::placeholder {
  color: rgb(var(--color-pencil3));
}

hr { margin-top: 1rem  }
hr { margin-bottom: 1rem  }

.ProseMirror p code {
  background: rgb(var(--color-wax2));
  padding: 0 0.25rem;
}

.ProseMirror > pre:not(:last-child) {
  margin-bottom: 1rem;
}

.ProseMirror > pre {
  background-color: rgb(var(--color-wax));
  padding: 0.25rem 0.5rem;
}

.ProseMirror > code {
  -webkit-autocorrect: off;
  -moz-autocorrect: off;
  -ms-autocorrect: off;
  autocorrect: off;
  spellcheck: false;
}

.avoid-column-break {
  break-inside: avoid-column;  
}