html, .color-scheme-light {
  --color-paper: var(--color-white);
  --color-paper2: var(--color-white);
  --color-rim: var(--color-gray-200);
  --color-rim2: var(--color-gray-300);
  --color-rim3: var(--color-gray-400);
  
  --color-wax: var(--color-gray-50);
  --color-wax2: var(--color-gray-100);
  --color-wax3: var(--color-gray-200);
  
  --color-pen: var(--color-gray-950);
  --color-pencil: var(--color-gray-900);
  --color-pencil2: var(--color-gray-700);
  --color-pencil3: var(--color-gray-500);

  --popup-title-bar-bg: linear-gradient(rgb(var(--color-gray-50)), rgb(var(--color-gray-100)));
  --color-scrollbar: var(--color-gray-300);

  --color-marker: var(--color-blue-500);
  --color-marker2: var(--color-blue-600);
  --color-marker3: var(--color-blue-700);
  --color-error: var(--color-red-500);
}

html.dark, .color-scheme-dark {
  color-scheme: dark;

  --color-paper: var(--color-gray-900);
  --color-paper2: var(--color-gray-925);
  --color-rim: var(--color-gray-800);
  --color-rim2: var(--color-gray-750);
  --color-rim3: var(--color-gray-700);
  
  --color-wax: var(--color-gray-850);
  --color-wax2: var(--color-gray-800);
  --color-wax3: var(--color-gray-700);
  
  --color-pen: var(--color-gray-300);
  --color-pencil: var(--color-gray-350);
  --color-pencil2: var(--color-gray-400);
  --color-pencil3: var(--color-gray-500);

  --popup-title-bar-bg: linear-gradient(rgb(var(--color-gray-50)), rgb(var(--color-gray-100)));
  --color-scrollbar: var(--color-gray-600);
  
  --color-marker: var(--color-blue-800);
  --color-marker2: var(--color-blue-700);
  --color-marker3: var(--color-blue-600);
  --color-error: var(--color-red-500);
  --popup-title-bar-bg: linear-gradient(rgb(var(--color-gray-800)), rgb(var(--color-gray-850)));
}